<template>
	<div class="applications">
		<div class="applications--images" v-if="applications">
			<div class="application--image-item" v-for="app in applications" :key="app.id" :data-id="app.id">
				<ImageById :media_id="app.featured_media" />
			</div>
		</div>
		<div class="applications--list" v-if="applications">
			<div class="application--item" v-for="(app, index) in applications" :key="app.id" :data-id="app.id">
				<router-link :to="$i18nRoute({ name: 'Application', params: {slug: app.slug} })">
					<div class="item--fonts">
						<div :data-id="app.id" class="item-font font-1" @mouseenter="changeCursorEnter" @mouseleave="changeCursorLeave">
							<span>{{app.title.rendered}}</span>
						</div>
					</div>
				</router-link>
				<div class="item--numeration">
					<span>{{setNum(index)}}</span>
				</div>
				<div class="mobile image">
					<ImageById :media_id="app.featured_media" />
				</div>
			</div>
		</div>
		<div class="scroll--down-label">
			<span>Scroll mouse for navigate</span>
		</div>
	</div>
</template>

<script>
import ImageById from '@/components/ImageById';
import axios from 'axios';
import { gsap } from 'gsap'
export default {
	data() {
		return {
			applications: [],
			mouseX: 0,
			mouseY: 0
		}
	},
	components: {
		ImageById
	},
	metaInfo() {
		return{
			title: this.$t('head.title.applications'),
			titleTemplate: '%s | Wearlight',
		}
  },
	methods: {
		changeCursorEnter: (event) => {
			let id = event.currentTarget.dataset.id;
			let cursor = document.getElementById('cursor')
			cursor.classList.add('onLink--big')

			gsap.to(document.querySelector('.applications--images .application--image-item[data-id="' + id + '"]'), {duration: 2, rotate: 0, scale: 1, autoAlpha: 1, ease: 'power2.out'})
			gsap.to(document.querySelector('.application--item[data-id="' + id + '"] .item--numeration'), {duration: 2, autoAlpha: 1, ease: 'power2.out'})
		},
		changeCursorLeave: () => {
			let id = event.currentTarget.dataset.id;
			let cursor = document.getElementById('cursor')
			cursor.classList.remove('onLink--big')

			gsap.to(document.querySelector('.applications--images .application--image-item[data-id="' + id + '"]'), {duration: 2, rotate: 20, scale: 1.1, autoAlpha: 0, ease: 'power2.out'})
			gsap.to(document.querySelector('.application--item[data-id="' + id + '"] .item--numeration'), {duration: 2, autoAlpha: 0, ease: 'power2.out'})
		},
		setNum(ind){
			let num = ind + 1;
			if(num > 9){
				return num;
			}else{
				return '0' + num;
			}
		}
	},
	beforeMount() {
		axios
			.get(process.env.VUE_APP_API_URL + '/wp/v2/application/?orderby=title&order=asc&per_page=20')
			.then(response => (this.applications = response.data));
			console.log(this.applications);
	},
	beforeRouteLeave(to, from, next) {
		let cursor = document.getElementById('cursor')
		cursor.classList.remove('onLink--big')
		next()
	}
}
</script>

<style lang="scss">
.applications {
	position: relative;
	width: 100%;
	padding-top: 20vh;
	padding-bottom: 20vh;
	display: flex;
	justify-content: center;
	@include mobile {
		padding: 10vh 5vw;
		box-sizing: border-box;
	}
	.applications--images {
		position: fixed;
		width: 30vw;
		top: 50vh;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 43;
		backface-visibility: hidden;
		user-select: none;
		pointer-events: none;
		@include mobile {
			display: none;
		}
		.application--image-item {
			position: absolute;
			width: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(20deg) scale(1.2);
			opacity: 0;
			visibility: hidden;
			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}
	}
	.applications--list {
		position: relative;
		width: 90vw;
		z-index: 42;
		@include mobile {
			width: 100%;
		}
		.application--item {
			position: relative;
			overflow: hidden;
			@include mobile {
				width: 100%;
				height: auto;
				margin-bottom: 10vh;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
			.image {
				display: none;
				@include mobile {
					display: block;
					position: relative;
					width: 100%;
					padding: 0 20vw;
					box-sizing: border-box;
					z-index: 2;
					img {
						width: 100%;
						height: auto;
					}
				}
			}
			a {
				display: inline-block;
				@include mobile {
					position: absolute;
					width: 100%;
					height: 100%;
				}
				.item--fonts {
					position: relative;
					@include mobile {
						width: 100%;
						height: 100%;
					}
					.item-font {
						@include mobile {
							width: 100%;
							height: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						&.font-1 {
							position: relative;
							font-family: 'Suisse Int';
							font-weight: 500;
							color: darken($light_grey, 10);
							transition: color .5s ease-in-out 0s;
							font-size: 9vw;
							line-height: 10vw;
							text-transform: uppercase;
							z-index: 50;
							@include mobile {
								font-size: 15vw;
								line-height: 15vw;
								width: 100%;
								height: 100%;
							}
							&:hover {
								color: $black;
								transition: color .5s ease-in-out 0s;
							}
						}
						&.font-2 {
							position: absolute;
							top: 0;
							left: 0;
							text-transform: uppercase;
							font-family: 'Suisse Int';
							font-size: 10vw;
							line-height: 10vw;
							font-weight: 500;
							color: $white;
							mix-blend-mode: difference;
							clip-path: circle(9vw at 50% 50%);
							background-color: $black;
							z-index: 49;
							//transform: translateY(-50%);
							@include mobile {
								display: none;
							}
						}
						span {
							display: block;
						}
					}
				}
			}
			.item--numeration {
				position: absolute;
				right: 0;
				top: 0;
				opacity: 0;
				visibility: hidden;
				@include mobile {
					display: none;
				}
				span {
					text-transform: uppercase;
					font-family: 'Suisse Int';
					font-size: 14px;
					line-height: 14px;
					font-weight: 500;
					color: $black;
				}
			}
		}
	}
	.scroll--down-label {
		position: fixed;
		bottom: 1vw;
		right: 1vw;
		transform-origin: bottom right;
		transform: rotate(90deg) translateY(100%);
		@include mobile {
			display: none;
		}
		span {
			text-transform: uppercase;
			font-family: 'Suisse Int';
			font-size: 14px;
			line-height: 14px;
			font-weight: 500;
			color: $black;
		}
	}
}
</style>